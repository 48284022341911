<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#staff"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">メンバーブリーダー</h1>
      </div>
      <div class="control-group">
        <button
          class="btn btn-warning btn-lg"
          @click.prevent="openModal"
        >＋追加</button>
      </div>
    </div>
  </header>

  <main class="p-3">

    <table class="table table-striped">
      <thead>
        <tr>
          <th>事業所名</th>
          <th>代表者</th>
          <th>連絡先</th>
          <th class="animal">保有数</th>
          <th class="staff">スタッフ数</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="row in breederList"
          :key="row.id"
          @click="showDetail(row.id)"
        >
          <td class="companyname" v-if="row.company_name">{{row.company_name}}</td>
          <td class="companyname notfilled" v-else>*事業所名未設定*</td>
          <td class="representative">{{row.name}}</td>
          <td class="tel">
            <dl v-if="row.tel"><dt>TEL</dt><dd>{{row.tel}}</dd></dl>
            <dl v-if="row.keitai"><dt>携帯</dt><dd>{{row.keitai}}</dd></dl>
          </td>
          <td class="animal">{{row.animal_count}}</td>
          <td class="staff">{{row.staff_count}}</td>
        </tr>
      </tbody>

    </table>

    <AddBreederModal
      :modal="modal"
      @reset="closeModal"
      @retriveBreeders="loadBreeders"
    />

  </main>
</template>

<script>
import axios from "axios"
import AddBreederModal from "@/components/Modal/AddBreeder"

export default {
  components: {
    AddBreederModal
  },
  data() {
    return {
      breederList: [],
      modal: false
    }
  },
  mounted() {
    this.loadBreeders()
  },
  methods: {
    loadBreeders() {
      axios
        .get("/wp-json/pasti/v1/breeders")
        .then( response => {
          this.breederList = response.data
        })
        .catch( error => {
          console.log(error);
        })
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    showDetail(id) {
      this.$router.push('/breeder/' + id)
    }
  }
}
</script>

<style scoped>
tr:hover {
  cursor: pointer;
}
.companyname,
.representative {
  width: 30%;
}
.representative {
  text-decoration: underline;
  color: var(--bs-primary)!important;
}
.animal,
.staff {
  width: 10%;
  text-align: center;
}
.companyname {
  font-weight: bold;
}
.notfilled {
  opacity: 0.6;
}
td.tel dl,
td.tel dl * {
  margin-bottom: 0;
  font-weight: normal;
}
td.tel dl {
  display: flex;
}
td.tel dt {
  width: 45px;
  flex-basis: 45px;
}
td.tel dd {
  flex: 1;
}
</style>