<template>
  <b-modal
    id="add-breeder-modal"
    title="ブリーダー追加"
    v-model="active"
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="cancel"
  >
    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="ログイン名（半角英数）"
        :class="{'is-invalid': !states.loginName}"
        v-model="loginName"
      >
      <label>ログイン名（半角英数のみ）</label>
      <div class="invalid-feedback" v-if="duplicate">既に存在するユーザー名です</div>
      <div class="invalid-feedback" v-else>ログイン名を半角英数で入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="お名前"
        :class="{'is-invalid': !states.displayName}"
        v-model="formData.displayName"
      >
      <label>お名前</label>
      <div class="invalid-feedback">お名前を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="事業所名"
        :class="{'is-invalid': !states.companyName}"
        v-model="formData.companyName"
      >
      <label>事業所名</label>
      <div class="invalid-feedback">事業所名を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="動物取扱業登録番号"
        v-model="formData.certifiedNum"
      >
      <label>動物取扱業登録番号</label>
      <div class="invalid-feedback">許可番号を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="所在地"
        :class="{'is-invalid': !states.addr}"
        v-model="formData.addr"
      >
      <label>所在地</label>
      <div class="invalid-feedback">所在地を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="tel"
        class="form-control"
        placeholder="連絡先電話番号"
        :class="{'is-invalid': !states.tel}"
        v-model="formData.tel"
      >
      <label>連絡先電話番号</label>
      <div class="invalid-feedback">連絡先電話番号を入力してください</div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >追加する</button>
    </div>

  </b-modal>

  <b-modal
    id="add-breeder-modal-qr"
    title="ログイン用QRコード"
    v-model="qr"
    ok-only
  >
    <Qrcode
      :loginUrl="loginUrl"
      :uid="uid"
      :password="password"
    />
  </b-modal>

</template>

<script>
import axios from "axios"
import Qrcode from "@/components/Qrcode"

export default {
  props: ["modal"],
  emits: ["reset", "retriveBreeders"],
  components: {
    Qrcode
  },
  data() {
    return {
      active: false,
      qr: false,
      loginUrl: "",
      uid: "",
      password: "",
      loginNameStr: "",
      duplicate: false,
      formData: {
        loginName: "",
        displayName: "",
        companyName: "",
        certifiedNum: "",
        addr: "",
        tel: "",
      },
      states: {
        loginName: true,
        displayName: true,
        companyName: true,
        addr: true,
        tel: true,
      },
    }
  },
  methods: {
    validate() {
      let res = true
      let states = this.states
      let formData = {...this.formData}
      Object.keys(states).forEach(function(key) {
        states[key] = formData[key] ? true : false
        res *= states[key]
      })
      return res
    },
    handleOk() {
      // if(!this.validate())
      //   return
      
      axios
        .post("/wp-json/pasti/v1/breeder/add", this.formData)
        .then( response => {
          console.log(response)
          // this.active = false
          this.showQR(response.data)
          this.resetForm()
          this.$emit('retriveBreeders')
        })
        .catch((error) => {
          console.log(error)
        });
    },
    cancel() {
      this.resetForm()
      this.$emit('reset')
    },
    resetForm() {
      let formdata = this.formData
      Object.keys(formdata).forEach(function(key) {
        formdata[key] = ""
      })
      this.formData = formdata

      let states = this.states
      Object.keys(states).forEach(function(key) {
        states[key] = true
      })
      this.states = states
    },
    checkAppropriate(str){
      str = (str === null) ? "" : str
      if(str.match(/^[A-Za-z0-9]*$/)){
        this.states.loginName = true
      }else{
        this.states.loginName = false
      }
    },
    validateLoginName(str) {
      axios
        .post("/wp-json/pasti/v1/breeder/checkduplicate", {
          chkstr: str
        })
        .then( response => {
          this.duplicate = response.data
        })
        .catch((error) => {
          console.log(error)
        });
    },
    showQR(data) {
      this.loginUrl = `https://pasti-animalwelfare.com/?uid=${data.uid}&pass=${data.password}`
      this.uid = data.uid
      this.password = data.password
      this.active = false
      this.qr = true
      console.log(this.loginUrl)
      console.log(this.uid)
      console.log(this.password)
    }
  },
  computed: {
    loginName: {
      get () {
        return this.formData.loginName
      },
      set (value) {
        console.log(value)
        this.loginNameStr = value
        this.formData.loginName = value
        this.checkAppropriate(value)
        this.validateLoginName(value)
      }
    }
  },
  watch: {
    modal: function(newVal) {
      this.active = newVal
    },
    duplicate: function(newVal) {
      if(newVal) {
        this.states.loginName = false
      }else{
        this.states.loginName = true
      }
    }
  }
}
</script>

<style scoped>
#qrcode > canvas {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 15px;
  background-color: #fff;
}
</style>